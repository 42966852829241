<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="wrap">
      <div class="h1">
        2024年浙江省临床放射质控中心培训会在杭召开
      </div>
      <section>
        <div class="div_p">
          2024年10月18日，浙江省临床放射质控培训会议在杭州浙商开元名都酒店召开，浙江省临床放射质控中心委员和顾问、各地市县区质控中心主任及秘书、二级（含）以上医院放射科主任共423名代表参加了本次会议。国家放射影像专业质控中心薛华丹副主任、甘肃省放射影像专业质控中心周俊林主任、浙江省人民医院张大宏副院长、浙江省医院协会周建新副秘书长、浙江省医疗服务管理评价中心缪建华副主任受邀出席会议。
        </div>
        <div>
          <img src="@/assets/HY20241018/1.png" alt="">
        </div>
        <div class="div_p">
          张大宏副院长代表浙江省人民医院和浙江省临床放射质控中心致辞，强调放射质控工作是保障医疗安全和提高医疗质量的重要环节，在当前的医疗改革背景下，要重视通过质控方式提高放射检查的规范性和放射报告的准确性，优化医疗资源的利用效率。周建新副秘书长简要介绍了浙江省医院协会，协会是医院管理工作者之家，为大家创造一个学习和交流的平台。周副秘书长充分肯定了放射管理专委会在龚向阳主委的带领下，在全省的“检查检验互认”工作中和专业学术上做出了很多创新性的贡献。缪建华主任充分肯定了临床放射质控中心在“浙医互认”、创新质控检查工作中的成绩，为其他的质控中心做了很好的榜样和示范。缪主任还介绍了新的等级医院评审的相关指标，强调现场检查比重将会减低，要重视日常的质量指标数据。
        </div>
        <div class="div_p">
          在培训环节，甘肃省放射影像质控中心主任周俊林教授以“以互认为抓手，促进医学影像高质量发展”为主题，详细介绍了甘肃省放射质控中心构成、职责、培训、技能比赛等方面的工作，克服地域跨度大、经济发展水平低的不足，通过建立放射影像技术联盟等特色举措，不断提高基层影像检查的同质化和提升诊断水平，为浙江省提供了可借鉴的经验。
        </div>
        <div class="div_p">
          宁波临床放射质控中心秘书叶贤旺主任和临平区临床放射质控中心主任阮桂祥分别代表市、区两级质控中心介绍经验。叶主任以《宁波市报告格式和检查互认规范化管理经验介绍》为题，介绍了宁波市质控中心在报告格式和检查互认工作中的经验；阮主任以《杭州市临平区影像同质化建设经验分析》为题，分享了临平区质控中心在影像同质化建设方面的经验，以医共体为依托，为提升基层医院影像同质化做了很好的示范。
        </div>
        <div class="div_p">
          浙江省临床放射质控中心常务副主任龚向阳教授分别作了《2023年浙江省各类各级医疗机构临床放射专业医疗服务与质量安全分析报告》、《浙江省三级医院质控检查结果反馈》两个专题报告。在第一个报告中，龚主任详细地介绍了2023年全省各级各类放射科人员、设备、信息化、工作量、服务效率指标、质控指标等统计分析数据，并通过分析比较近5年的数据，结合国家相关政策对未来放射学科的发展趋势进行展望。在第二个报告中，龚主任向参会代表通报了2024年完成的全省三级医院线上质控检查结果，详细分析了质控检查中发现的对码质量、图像质量和诊报告质量共性问题，表扬了质控检查中表现优异医院及其做法，为浙江省放射检查互认共享、检查和诊断同质化指明了方向。
        </div>
        <div>
          <img src="@/assets/HY20241018/2.png" alt="">
        </div>
        <div class="div_p">
          最后，国家放射影像专业质控中心副主任薛华丹教授作了《国家放射影像专业质控中心工作简介》的报告，详细介绍了国家质控中心在完善组织织网工作、推进指标体系建设和深化破壁工作模式三个方面内容，使全省放射质控同行能够及时、准确地理解国家质控中心的工作任务和具体要求，更好地落实国家质控指标上报、专项调研等各项任务，也为省级质控中心工作指明了方向。
        </div>
        <div>
          <img src="@/assets/HY20241018/3.png" alt="">
        </div>
        <div class="div_p">
          本次培训会聚焦临床放射质控工作中的痛点和难点，实现国家、省级、市级和县区级四级质控体系的联动和交流，参会代表充分讨论、积极交流，必将有力地推动浙江临床放射质控工作的稳步提升。
        </div>
        <div>
          <img src="@/assets/HY20241018/4.png" alt="">
        </div>

      </section>

    </div>
  </div>
</template>

<script>
export default {
  name: "HY20241018",
  data() {
    return {

    }
  },
}
</script>

<style scoped>
.wrap {
  max-width: 677px;
  margin: 40px auto;
  color: rgb(0, 0, 0);
  background: #fff;
  padding: 10px;
  line-height: 2;
}

img {
  width: 100%;
  box-sizing: border-box;
  margin: 10px auto;
  display: block;
}

.img-list img {
  display: inline;
  width: 50%;
  margin: 0;
}

.h1 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.h2 {
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.div_p {
  text-indent: 2.125em;
  white-space: normal;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  visibility: visible;
}

.div_tit {
  text-align: center;
}
</style>